(function($) {

    function new_map( $el ) {
        var $markers = $el.find('.marker');

        var args = {
            zoom        : 18,
            center        : new google.maps.LatLng(0, 0),
            mapTypeId     : google.maps.MapTypeId.ROADMAP,
            scrollwheel     : false,
            navigationControl : false,
            mapTypeControl    : false,
            scaleControl    : true,
            draggable     : true,
            disableDefaultUI  : false,
            styles: [
                {
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#bdbdbd"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        }
                    ]
                },
                {
                    "featureType": "poi.attraction",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#e4e7e7"
                        },
                        {
                            "lightness": 20
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.attraction",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.medical",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "saturation": 15
                        },
                        {
                            "lightness": 70
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#a9bdbd"
                        },
                        {
                            "saturation": -10
                        },
                        {
                            "lightness": 100
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#757575"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dadada"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#616161"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e5e5e5"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#eeeeee"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#c9c9c9"
                        },
                        {
                            "saturation": -50
                        },
                        {
                            "lightness": 35
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#6a7c7c"
                        },
                        {
                            "saturation": 10
                        },
                        {
                            "lightness": -20
                        },
                        {
                            "visibility": "on"
                        },
                        {
                            "weight": 1.5
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#9e9e9e"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ]
        };

        var map = new google.maps.Map( $el[0], args);
        map.markers = [];

        $markers.each(function(){
            add_marker( $(this), map );
        });

        center_map( map );

        return map;

    }

    function add_marker( $marker, map ) {

        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        var icon_size = 100;

        if(jQuery('html').hasClass('mobile')){
            icon_size = 75;
        }

        var icon = {
            url: "https://keywork.thekitchen.agency/wp-content/themes/keywork/dist/images/mapIcon.png",
            scaledSize: new google.maps.Size(174, 180),
        };

        var marker = new google.maps.Marker({
            position  : latlng,
            icon        : icon,
            //size        : new google.maps.Size(65,96),
            map     : map
        });

        map.markers.push( marker );

        if( $marker.html() ){

            var infowindow = new google.maps.InfoWindow({
                content   : $marker.html()
            });

            google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );
            });
        }
    }

    function center_map( map ) {
        var bounds = new google.maps.LatLngBounds();
        var latlng;

        $.each( map.markers, function( i, marker ){
            latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
        });

        if( map.markers.length == 1 ){
            map.setCenter( bounds.getCenter() );
            map.setZoom( 17 );
            map.panBy(0,0);
        } else {
            // fit to bounds
            map.fitBounds( bounds );
        }
    }

    var map = null;

    $(document).ready(function(){
        $('.acf-map').each(function(){
            // create map
            map = new_map( $(this) );
        });
    });

})(jQuery);
